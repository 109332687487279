import React, { useState, useRef, useEffect, useCallback } from 'react';
import Map, { Marker, NavigationControl, Source, Layer } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const PropertyMap = ({ 
  properties, 
  onPropertyClick, 
  hoveredProperty, 
  searchedZipCode,
  searchedNeighborhood,
  zipCodeBoundary,
  neighborhoodBoundary,
  zipCodeData,
  neighborhoodData,
  mapBounds,
  selectedProperty,
  isMobile,
  onClick
}) => {
  console.log('PropertyMap rendered with:', { properties: properties.length, hoveredProperty, searchedZipCode, searchedNeighborhood, zipCodeBoundary, neighborhoodBoundary, zipCodeData, neighborhoodData, mapBounds });
  
  const [popupInfo, setPopupInfo] = useState(null);
  const [viewState, setViewState] = useState({
    latitude: 34.0522,
    longitude: -118.2437,
    zoom: 10
  });
  const mapRef = useRef(null);
  const hoverTimeoutRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const formatPriceShort = (price) => {
    if (price >= 1000000) {
      return `$${(price / 1000000).toFixed(1)}M`;
    } else if (price >= 1000) {
      return `$${(price / 1000).toFixed(0)}K`;
    } else {
      return `$${price}`;
    }
  };

  const formatAddress = (address) => {
    if (typeof address === 'string') return address;
    return `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipcode}`;
  };

  const handleMarkerMouseEnter = (property) => {
    if (!selectedProperty) {
      hoverTimeoutRef.current = setTimeout(() => {
        setPopupInfo(property);
      }, 500);
    }
  };

  const handleMarkerMouseLeave = () => {
    clearTimeout(hoverTimeoutRef.current);
    setPopupInfo(null);
  };

  const fitMapToBounds = useCallback(() => {
    if (mapBounds && mapRef.current && mapLoaded) {
      console.log('Fitting map to bounds:', mapBounds);
      mapRef.current.fitBounds(mapBounds, {
        padding: 60,
        maxZoom: 15,
        duration: 1000
      });
    } else {
      console.log('Unable to fit map to bounds. mapBounds:', mapBounds, 'mapRef.current:', mapRef.current, 'mapLoaded:', mapLoaded);
    }
  }, [mapBounds, mapLoaded]);

  useEffect(() => {
    console.log('mapBounds or mapLoaded changed:', mapBounds, mapLoaded);
    fitMapToBounds();
  }, [mapBounds, mapLoaded, fitMapToBounds]);

  const handleMapLoad = useCallback(() => {
    console.log('Map loaded');
    setMapLoaded(true);
  }, []);

  const handleMarkerClick = (property, event) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the map
    setPopupInfo(null);
    onPropertyClick(property);
  };

  const handleMapClick = (event) => {
    // Check if the click is on the map and not on a marker
    if (event.originalEvent && event.originalEvent.target && event.originalEvent.target.classList.contains('mapboxgl-canvas')) {
      onClick();
    }
  };

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={evt => setViewState(evt.viewState)}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      style={{width: '100%', height: '100%'}}
      mapStyle="mapbox://styles/mapbox/dark-v10"
      onLoad={handleMapLoad}
      onClick={handleMapClick}
      className="mapboxgl-map-custom"
    >
      <NavigationControl position="top-right" />
      {properties && properties.length > 0 && properties.map(property => (
        <Marker
          key={property.zpid}
          latitude={property.latitude}
          longitude={property.longitude}
          anchor="bottom"
        >
          <div 
            className={`mapboxgl-marker ${hoveredProperty === property.zpid ? 'hovered' : ''}`}
            style={{
              width: '60px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              fontSize: '10px',
              fontWeight: '600',
              color: 'black',
              cursor: 'pointer',
              backgroundColor: selectedProperty === property.zpid ? '#dcb482' : 'white', // Dark red for selected, white for default
              transition: 'all 0.3s ease',
              transform: (hoveredProperty === property.zpid || selectedProperty === property.zpid) ? 'scale(1.1)' : 'scale(1)',
              border: '1px solid black',
            }}
            onClick={(e) => handleMarkerClick(property, e)}
            onMouseEnter={() => handleMarkerMouseEnter(property)}
            onMouseLeave={handleMarkerMouseLeave}
          >
            {formatPriceShort(property.price)}
          </div>
          {!isMobile && popupInfo === property && !selectedProperty && (
            <div 
              className="absolute bottom-full left-1/2 transform -translate-x-1/3 mb-2 bg-black text-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 ease-in-out cursor-pointer"
              style={{
                width: '200px',
                zIndex: 1001,
              }}
              onClick={() => onPropertyClick(property)}
            >
              <div className="relative">
                <img 
                  src={property.images[0]} 
                  alt={property.address} 
                  className="w-full h-24 object-cover"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-2">
                  <h3 className="text-sm font-bold mb-1">{formatPriceShort(property.price)}</h3>
                  <p className="text-xs truncate">{formatAddress(property.address)}</p>
                  <div className="flex items-center text-xs mt-1">
                    <span className="mr-2">{property.bedrooms} beds</span>
                    <span className="mr-2">{property.bathrooms} baths</span>
                    {property.livingArea && (
                      <span>{property.livingArea.toLocaleString()} sqft</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Marker>
      ))}
      {zipCodeBoundary && (
        <Source id="zipcode-boundary" type="geojson" data={zipCodeBoundary}>
          <Layer
            id="zipcode-outline"
            type="line"
            paint={{
              'line-color': '#dcb482',
              'line-width': 3
            }}
          />
        </Source>
      )}
      {neighborhoodBoundary && (
        <Source id="neighborhood-boundary" type="geojson" data={neighborhoodBoundary}>
          <Layer
            id="neighborhood-outline"
            type="line"
            paint={{
              'line-color': '#dcb482',
              'line-width': 3
            }}
          />
        </Source>
      )}
    </Map>
  );
};

export default PropertyMap;