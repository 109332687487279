import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HeimLuxuryListing from './LuxuryHomeListing';
import SearchResults from './SearchResults';
import GlobalHeader from './components/GlobalHeader';
import Footer from './components/Footer';
import AuthModal from './components/AuthModal';
import { UserProvider, UserContext } from './UserContext';
import './App.css';
import SavedProperties from './SavedProperties';
import PropertyPage from './PropertyPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function AppContent() {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [authMode, setAuthMode] = useState('signup');
  const { user, updateUser } = useContext(UserContext);
  const [savedProperties, setSavedProperties] = useState([]);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuthStatus = async () => {
      let token = localStorage.getItem('token');
      let storedUser = JSON.parse(localStorage.getItem('user'));

      // Check for token in URL (for Google Auth)
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');

      if (tokenFromUrl) {
        console.log('Token found in URL:', tokenFromUrl);
        token = tokenFromUrl;
        localStorage.setItem('token', tokenFromUrl);
        window.history.replaceState({}, document.title, "/");
      }

      if (token && storedUser && !user) {
        updateUser(storedUser);
        setIsCheckingAuth(false);
        return;
      }

      if (token && !user) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/check-auth`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          });

          if (response.ok) {
            const userData = await response.json();
            updateUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            updateUser(null);
          }
        } catch (error) {
          console.error('Error checking auth status:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          updateUser(null);
        }
      } else if (!token && user) {
        updateUser(null);
      }
      setIsCheckingAuth(false);
    };

    checkAuthStatus();
  }, [user, updateUser]);

  // Add this useEffect hook back for loading Google script
  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };
    loadGoogleScript();
  }, []);

  const handleOpenAuthModal = (mode) => {
    setAuthMode(mode);
    setIsAuthModalOpen(true);
  };

  const handleCloseAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  const handleAuthSubmit = async (data) => {
    updateUser(data);
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data));
    setIsAuthModalOpen(false);
  };

  const isPropertyPage = location.pathname.includes('/homes-for-sale/');

  if (isCheckingAuth) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {!isPropertyPage && !isAuthModalOpen && (
        <GlobalHeader 
          onOpenAuthModal={handleOpenAuthModal} 
          setSavedProperties={setSavedProperties}
          isAuthModalOpen={isAuthModalOpen}
        />
      )}
      <div className={`flex-grow ${isAuthModalOpen ? 'blur-background' : ''}`}>
        <Routes>
          <Route path="/" element={
            <HeimLuxuryListing 
              onOpenAuthModal={handleOpenAuthModal}
            />
          } />
          <Route path="/search/:zipcode" element={
            <SearchResults 
              onOpenAuthModal={handleOpenAuthModal}
            />
          } />
          <Route path="/saved-homes" element={<SavedProperties />} />
          <Route path="/homes-for-sale/:address/:zpid" element={<PropertyPage />} />
        </Routes>
      </div>
      <Footer />
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={handleCloseAuthModal}
        onSubmit={handleAuthSubmit}
        mode={authMode}
        onSwitchMode={handleOpenAuthModal}
        className="auth-modal"
      />
    </div>
  );
}

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <UserProvider>
          <AppContent />
        </UserProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;