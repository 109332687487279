import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropertyModal from './PropertyModal';
import AuthModal from './components/AuthModal';
import axios from 'axios';
import { UserContext } from './UserContext';
import { Loader2 } from 'lucide-react';

const PropertyPage = () => {
  const { zpid } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user, updateUser } = useContext(UserContext);
  const [savedProperties, setSavedProperties] = useState([]);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [authMode, setAuthMode] = useState('signup');

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/${zpid}`);
        setProperty(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching property:', error);
        navigate('/');
      }
    };

    fetchProperty();
  }, [zpid, navigate]);

  useEffect(() => {
    if (user) {
      setSavedProperties(user.savedPosts || []);
    }
  }, [user]);

  const handleClose = () => {
    navigate('/');
  };

  const handleSaveProperty = async (propertyId) => {
    if (!user) {
      setIsAuthModalOpen(true);
      setAuthMode('signup');
      return;
    }

    try {
      const endpoint = savedProperties.includes(propertyId)
        ? `${process.env.REACT_APP_API_URL}/api/auth/unsave-property`
        : `${process.env.REACT_APP_API_URL}/api/auth/save-property`;

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id, propertyId: propertyId.toString() }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSavedProperties(data.savedPosts);
      updateUser({ ...user, savedPosts: data.savedPosts });
    } catch (error) {
      console.error('Error saving/unsaving property:', error);
    }
  };

  const handleOpenAuthModal = (mode) => {
    setAuthMode(mode);
    setIsAuthModalOpen(true);
  };

  const handleCloseAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  const handleAuthSubmit = async (data) => {
    updateUser(data);
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data));
    setIsAuthModalOpen(false);
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black flex items-center justify-center z-50">
        <div className="text-white text-2xl flex flex-col items-center">
          <Loader2 className="animate-spin mb-4" size={48} />
          <p>Loading property details...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{property ? `${property.address.streetAddress} | Heim Luxury Real Estate` : 'Loading...'}</title>
        <meta name="description" content={property ? `Explore this ${property.bedrooms} bed, ${property.bathrooms} bath, ${property.livingArea} sqft luxury home in ${property.address.city}, ${property.address.state}.` : ''} />
      </Helmet>
      
      <div className={`${isAuthModalOpen ? 'blur-sm' : ''}`}>
        {property && (
          <PropertyModal
            property={property}
            onClose={handleClose}
            onOpenAuthModal={handleOpenAuthModal}
            user={user}
            onSaveProperty={handleSaveProperty}
            savedProperties={savedProperties}
          />
        )}
      </div>

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={handleCloseAuthModal}
        onSubmit={handleAuthSubmit}
        mode={authMode}
        onSwitchMode={handleOpenAuthModal}
        className="auth-modal z-[70]"
      />
    </>
  );
};

export default PropertyPage;