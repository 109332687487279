import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-12 mt-16">
      <div className="container mx-auto px-4 text-center">
        <p className="mb-2 text-sm font-light">&copy; 2024 Heim Luxury Estates. All rights reserved.</p>
        <p className="text-xs font-light max-w-xl mx-auto">
          Heim curates extraordinary living spaces in Los Angeles.
        </p>
      </div>
    </footer>
  );
};

export default Footer;