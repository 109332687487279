export const architecturalStyleImages = {
    'Contemporary': '/architectural-styles/contemporary.png',
    'Modern': '/architectural-styles/modern.png',
    'Traditional': '/architectural-styles/traditional.png',
    'Mediterranean': '/architectural-styles/mediterranean.png',
    'A-Frame': '/architectural-styles/a frame.png',
    'Adobe': '/architectural-styles/adobe.png',
    'Architectural': '/architectural-styles/architectural.png',
    'Cape Cod': '/architectural-styles/cape cod.png',
    'Colonial': '/architectural-styles/colonial.png',
    'Country French': '/architectural-styles/country french.png',
    'Country': '/architectural-styles/country.png',
    'Craftsman': '/architectural-styles/craftsman.png',
    'English': '/architectural-styles/english.png',
    'Farm House': '/architectural-styles/farmhouse.png',
    'French Normandy': '/architectural-styles/french normandy.png',
    'French': '/architectural-styles/french.png',
    'Hacienda': '/architectural-styles/hacienda.png',
    'Mid Century Modern': '/architectural-styles/mid-century modern.png',
    'Mid Century': '/architectural-styles/mid-century.png',
    'Ranch': '/architectural-styles/ranch.png',
    'Southern Colonial': '/architectural-styles/southern colonial.png',
    'Spanish': '/architectural-styles/spanish.png',
    'Tudor': '/architectural-styles/tudor.png',
    'Victorian': '/architectural-styles/victorian.png',
    'Villa': '/architectural-styles/villa.png'
    // Add all other styles here
  };
  
  export const getStyleImage = (style) => {
    return architecturalStyleImages[style] || null;
};