import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import { UserContext } from '../UserContext';

const GlobalHeader = ({ onOpenAuthModal, setSavedProperties, isPropertyModalOpen, isAuthModalOpen }) => {
  const { user, updateUser } = useContext(UserContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include'
      });
      if (response.ok) {
        updateUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setSavedProperties([]);
        setIsUserMenuOpen(false);
        navigate('/');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <>
      <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-black/50 backdrop-blur-md' : 'bg-transparent'
      } ${isPropertyModalOpen || isAuthModalOpen ? 'hidden' : ''}`}>
        <div className="container mx-auto px-4 py-4 flex items-center justify-between h-16">
          <Link to="/" className="text-2xl font-light tracking-wider text-white">HEIM</Link>
          <div className="flex items-center space-x-4">
            {user ? (
              <div className="relative" ref={userMenuRef}>
                <button 
                  onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                  className="flex items-center space-x-2 text-white hover:text-gray-300 h-10"
                >
                  <img 
                    src={user.googleProfilePhoto || 
                      (user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar}` : '/LuxuryRE_Profile_photos_svg/1.svg')} 
                    alt={user.firstName} 
                    className="w-10 h-10 rounded-full"
                    onError={(e) => {
                      console.error('Error loading avatar:', e);
                      e.target.src = '/LuxuryRE_Profile_photos_svg/1.svg'; // Fallback to the first avatar
                    }}
                  />
                  <span>Welcome, {user.firstName}</span>
                </button>
                {isUserMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    <Link to="/saved-homes" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">View Saved Homes</Link>
                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="hidden lg:flex items-center space-x-4 h-8">
                  <button onClick={() => onOpenAuthModal('signup')} className="bg-white text-black px-4 py-2 rounded-full text-sm font-medium">
                    Sign Up
                  </button>
                  <button onClick={() => onOpenAuthModal('login')} className="bg-transparent text-white px-4 py-2 rounded-full text-sm font-medium border border-white">
                    Log In
                  </button>
                </div>
                <button 
                  onClick={toggleMobileMenu}
                  className="lg:hidden z-50 relative w-8 h-8 flex flex-col justify-center items-end"
                  aria-label="Navigation toggle"
                >
                  <span className={`bg-white h-0.5 rounded-full w-6 transition-all duration-300 ${isMobileMenuOpen ? 'rotate-45 translate-y-1.5' : ''}`}></span>
                  <span className={`bg-white h-0.5 rounded-full w-5 mt-1.5 transition-all duration-300 ${isMobileMenuOpen ? 'opacity-0' : ''}`}></span>
                  <span className={`bg-white h-0.5 rounded-full w-4 mt-1.5 transition-all duration-300 ${isMobileMenuOpen ? '-rotate-45 -translate-y-1.5 w-6' : ''}`}></span>
                </button>
              </>
            )}
          </div>
        </div>
      </header>
      {isMobileMenuOpen && !user && (
        <div className="fixed inset-0 bg-black/90 z-40 flex flex-col items-center justify-center">
          <button 
            onClick={() => {
              onOpenAuthModal('signup');
              setIsMobileMenuOpen(false);
            }}
            className="bg-white text-black px-6 py-3 rounded-full text-lg font-medium mb-4 w-64"
          >
            Sign Up
          </button>
          <button 
            onClick={() => {
              onOpenAuthModal('login');
              setIsMobileMenuOpen(false);
            }}
            className="bg-transparent text-white px-6 py-3 rounded-full text-lg font-medium border border-white w-64"
          >
            Log In
          </button>
        </div>
      )}
    </>
  );
};

export default GlobalHeader;