import React, { useState, useEffect, useContext } from 'react';
import { ChevronDown, ChevronUp, Star, ChevronLeft, ChevronRight } from 'lucide-react';
import { UserContext } from './UserContext';
import PropertyModal from './PropertyModal';
import { useNavigate, useLocation } from 'react-router-dom';

const SavedProperties = () => {
  const { user, updateUser } = useContext(UserContext);
  const [savedProperties, setSavedProperties] = useState([]);
  const [groupedProperties, setGroupedProperties] = useState({});
  const [expandedNeighborhoods, setExpandedNeighborhoods] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [error, setError] = useState(null);
  const [currentImageIndexes, setCurrentImageIndexes] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user && user.id) {
      fetchSavedProperties(user.id);
    }
  }, [user]);

  const fetchSavedProperties = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/user-saved-properties/${userId}`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched saved properties:', data);
        if (Array.isArray(data.savedPosts)) {
          setSavedProperties(data.savedPosts);
          fetchPropertyDetails(data.savedPosts);
        } else {
          console.error('Saved properties is not an array:', data.savedPosts);
          setError('Unable to load saved properties. Please try again later.');
        }
      } else {
        throw new Error('Failed to fetch saved properties');
      }
    } catch (error) {
      console.error('Error fetching saved properties:', error);
      setError('Unable to load saved properties. Please try again later.');
    }
  };

  const fetchPropertyDetails = async (propertyIds) => {
    try {
      const promises = propertyIds.map(id => 
        fetch(`${process.env.REACT_APP_API_URL}/api/properties/${id}`, { credentials: 'include' }).then(res => res.json())
      );
      const propertyDetails = await Promise.all(promises);
      console.log('Fetched property details:', propertyDetails);
      const propertiesWithNeighborhood = propertyDetails.map(property => ({
        ...property,
        neighborhood: property.neighborhood || 'Los Angeles'
      }));
      groupPropertiesByNeighborhood(propertiesWithNeighborhood);
    } catch (error) {
      console.error('Error fetching property details:', error);
      setError('Unable to load property details. Please try again later.');
    }
  };

  const groupPropertiesByNeighborhood = (properties) => {
    if (!properties || properties.length === 0) {
      setGroupedProperties({});
      return;
    }
    const grouped = properties.reduce((acc, property) => {
      const neighborhood = property.neighborhood || 'Los Angeles';
      if (!acc[neighborhood]) {
        acc[neighborhood] = [];
      }
      acc[neighborhood].push(property);
      return acc;
    }, {});
    setGroupedProperties(grouped);
    setExpandedNeighborhoods(Object.keys(grouped).reduce((acc, neighborhood) => {
      acc[neighborhood] = true;
      return acc;
    }, {}));
  };

  const toggleNeighborhood = (neighborhood) => {
    setExpandedNeighborhoods(prev => ({
      ...prev,
      [neighborhood]: !prev[neighborhood]
    }));
  };

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    const formattedAddress = formatAddress(property.address);
    const encodedAddress = encodeURIComponent(`${formattedAddress.street}-${formattedAddress.cityStateZip}`.replace(/\s/g, '-').toLowerCase());
    const newUrl = `/homes-for-sale/${encodedAddress}/${property.zpid}`;
    navigate(newUrl, { state: { background: location, property: property } });
  };

  const handleCloseModal = () => {
    setSelectedProperty(null);
    navigate(location.pathname, { replace: true });
  };

  const formatAddress = (address) => {
    return {
      street: address.streetAddress,
      cityStateZip: `${address.city}, ${address.state} ${address.zipcode}`
    };
  };

  const formatPriceShort = (price) => {
    if (price >= 1000000) {
      return `$${(price / 1000000).toFixed(1)}M`;
    } else if (price >= 1000) {
      return `$${(price / 1000).toFixed(0)}K`;
    } else {
      return `$${price}`;
    }
  };

  const onSaveProperty = async (propertyId) => {
    if (!user) {
      return;
    }

    try {
      const endpoint = savedProperties.includes(propertyId) 
        ? `${process.env.REACT_APP_API_URL}/api/auth/unsave-property` 
        : `${process.env.REACT_APP_API_URL}/api/auth/save-property`;
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id, propertyId: propertyId.toString() }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Update local state immediately
      if (savedProperties.includes(propertyId)) {
        // If unsaving, remove the property from the list
        setGroupedProperties(prevGrouped => {
          const newGrouped = { ...prevGrouped };
          Object.keys(newGrouped).forEach(neighborhood => {
            newGrouped[neighborhood] = newGrouped[neighborhood].filter(prop => prop.zpid !== propertyId);
          });
          // Remove empty neighborhoods
          Object.keys(newGrouped).forEach(neighborhood => {
            if (newGrouped[neighborhood].length === 0) {
              delete newGrouped[neighborhood];
            }
          });
          return newGrouped;
        });
        setSavedProperties(prev => prev.filter(id => id !== propertyId));
      } else {
        // If saving, we don't need to add it to the list as it's already displayed
        setSavedProperties(prev => [...prev, propertyId]);
      }

      // Update user context
      updateUser({ ...user, savedPosts: data.savedPosts });

    } catch (error) {
      console.error('Error saving/unsaving property:', error);
      setError('Unable to save/unsave property. Please try again later.');
    }
  };

  const handleImageScroll = (propertyZpid, direction) => {
    setCurrentImageIndexes(prevIndexes => {
      const currentIndex = prevIndexes[propertyZpid] || 0;
      const property = Object.values(groupedProperties).flat().find(p => p.zpid === propertyZpid);
      if (!property || !property.images) return prevIndexes;
      const imagesCount = property.images.length;
      let newIndex;

      if (direction === 'left') {
        newIndex = (currentIndex - 1 + imagesCount) % imagesCount;
      } else {
        newIndex = (currentIndex + 1) % imagesCount;
      }

      return { ...prevIndexes, [propertyZpid]: newIndex };
    });
  };

  return (
    <div className="bg-black text-white font-sans min-h-screen">
      <main className="container mx-auto px-4 py-12 mt-16 sm:mt-0"> {/* Added mt-16 for mobile, sm:mt-0 for larger screens */}
        <h1 className="text-5xl font-light mb-4 text-center tracking-wide leading-tight pt-8 sm:pt-0"> {/* Added pt-8 for mobile, sm:pt-0 for larger screens */}
          Your Saved Properties
        </h1>
        <p className="text-lg text-center mb-12 max-w-2xl mx-auto text-gray-400 font-light leading-relaxed">
          Explore your curated collection of luxury estates.
        </p>

        {error && (
          <p className="text-red-500 text-center mb-4">{error}</p>
        )}

        {Object.keys(groupedProperties).length === 0 && !error && (
          <p className="text-center text-gray-400">You haven't saved any properties yet.</p>
        )}

        {Object.entries(groupedProperties).map(([neighborhood, properties]) => (
          <div key={neighborhood} className="mb-8">
            <div 
              className="flex justify-between items-center cursor-pointer mb-4"
              onClick={() => toggleNeighborhood(neighborhood)}
            >
              <h2 className="text-2xl font-semibold">{neighborhood}</h2>
              {expandedNeighborhoods[neighborhood] ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
            </div>
            <div className={`neighborhood-content ${expandedNeighborhoods[neighborhood] ? 'expanded' : ''}`}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {properties.map((property) => {
                  const currentImageIndex = currentImageIndexes[property.zpid] || 0;
                  return (
                    <div 
                      key={property.zpid} 
                      className="card-wrapper group pointer-events-auto relative grid aspect-square h-full w-full grid-rows-[1fr_auto] gap-3 rounded-lg sm:aspect-auto sm:grid-rows-[294px_auto] p-1 transform transition-all duration-300 hover:scale-[1.025] hover:shadow-lg cursor-pointer"
                      onClick={() => handlePropertyClick(property)}
                    >
                      <div className="card relative isolate h-full w-full rounded-lg overflow-hidden">
                        <div className="scrollbar-hide pointer-events-auto relative inset-0 flex snap-x snap-mandatory overflow-x-auto w-full object-cover h-full">
                          <img src={property.images[currentImageIndex]} alt={formatAddress(property.address).street} className="h-full w-full flex-none snap-center snap-always overflow-hidden object-cover" />
                        </div>
                        <button
                          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1.5 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100 z-10 w-8 h-8 flex items-center justify-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageScroll(property.zpid, 'left');
                          }}
                          aria-label="prev picture"
                        >
                          <ChevronLeft size={20} />
                        </button>
                        <button
                          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1.5 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100 z-10 w-8 h-8 flex items-center justify-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageScroll(property.zpid, 'right');
                          }}
                          aria-label="next picture"
                        >
  <ChevronRight size={20} />
</button>
                        <div 
                          className="absolute top-2 right-2 z-10"
                          onClick={(e) => {
                            e.stopPropagation();
                            onSaveProperty(property.zpid);
                          }}
                        >
                          <Star 
                            size={24} 
                            fill={savedProperties.includes(property.zpid) ? "currentColor" : "none"}
                            className="text-white hover:text-yellow-500 transition-colors duration-200" 
                          />
                        </div>
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
                          <h3 className="text-xl font-semibold text-white mb-1">{property.address.streetAddress}</h3>
                          <p className="text-sm text-gray-300 mb-2">{`${property.address.city}, ${property.address.state} ${property.address.zipcode}`}</p>
                          <p className="text-lg font-light text-white">{property.price ? `$${property.price.toLocaleString()}` : 'Price not available'}</p>
                          <div className="flex justify-between text-xs text-gray-300 mt-2">
                            <span>{property.bedrooms} beds</span>
                            <span>{property.bathrooms} baths</span>
                            {property.livingArea && <span>{property.livingArea.toLocaleString()} sqft</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </main>

      {selectedProperty && (
        <PropertyModal 
          property={selectedProperty} 
          onClose={handleCloseModal} 
          onOpenAuthModal={() => {}} // No-op for authenticated users
          user={user}
          onSaveProperty={onSaveProperty}
          savedProperties={savedProperties}
        />
      )}
    </div>
  );
};

export default SavedProperties;