export const neighborhoods = {
    'Baldwin Hills/Crenshaw': '/images/baldwin-hills.jpg',
    'Bel-Air': '/images/bel-air.jpg',
    'Beverly Crest': '/images/beverly-crest.jpg',
    'Beverly Grove': '/images/beverly-grove.jpg',
    'Beverlywood': '/images/beverlywood.jpg',
    'Brentwood': '/images/brentwood.jpg',
    'Carthay': '/images/carthay.jpg',
    'Century City': '/images/century-city.jpg',
    'Chatsworth': '/images/chatsworth.jpg',
    'Cheviot Hills': '/images/cheviot-hills.jpg',
    'Encino': '/images/encino.jpg',
    'Fairfax': '/images/fairfax.jpg',
    'Granada Hills': '/images/granada-hills.jpg',
    'Hancock Park': '/images/hancock-park.jpg',
    'Hollywood': '/images/hollywood.jpg',
    'Hollywood Hills': '/images/hollywood-hills.jpg',
    'Hollywood Hills West': '/images/hollywood-hills-west.jpg',
    'Larchmont': '/images/larchmont.jpg',
    'Los Feliz': '/images/los-feliz.jpg',
    'Mar Vista': '/images/mar-vista.jpg',
    'Mid-City': '/images/mid-city.jpg',
    'Mid-Wilshire': '/images/mid-wilshire.jpg',
    'Mount Washington': '/images/mount-washington.jpg',
    'North Hollywood': '/images/north-hollywood.jpg',
    'Pacific Palisades': '/images/pacific-palisades.jpg',
    'Palms': '/images/palms.jpg',
    'Playa Vista': '/images/playa-vista.jpg',
    'Santa Monica': '/images/santa-monica.jpg',
    'Shadow Hills': '/images/shadow-hills.jpg',
    'Sherman Oaks': '/images/sherman-oaks.jpg',
    'Silver Lake': '/images/silver-lake.jpg',
    'Studio City': '/images/studio-city.jpg',
    'Tarzana': '/images/tarzana.jpg',
    'Toluca Lake': '/images/toluca-lake.jpg',
    'Tujunga': '/images/tujunga.jpg',
    'Valley Village': '/images/valley-village.jpg',
    'Venice': '/images/venice.jpg',
    'West Hills': '/images/west-hills.jpg',
    'West Los Angeles': '/images/west-los-angeles.jpg',
    'Westchester': '/images/westchester.jpg',
    'Westwood': '/images/westwood.jpg',
    'Windsor Square': '/images/windsor-square.jpg',
    'Woodland Hills': '/images/woodland-hills.jpg',
    'Malibu': '/images/malibu.jpg',
};

export const getNeighborhoodImage = (neighborhood) => {
    return neighborhoods[neighborhood] || null;
};