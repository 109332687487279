import React, { useState, useEffect, useCallback, useRef } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

const FullScreenImage = ({ images, onClose, initialIndex = 0 }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [clickedOutside, setClickedOutside] = useState(false);
  const modalRef = useRef(null);
  const thumbnailsRef = useRef(null);
  const currentThumbnailRef = useRef(null);

  const onPrev = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  }, [images.length]);

  const onNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  }, [images.length]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      onPrev();
    } else if (event.key === 'ArrowRight') {
      onNext();
    } else if (event.key === 'Escape') {
      onClose();
    }
  }, [onPrev, onNext, onClose]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (currentThumbnailRef.current) {
      currentThumbnailRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [currentIndex]);

  const handleMouseDown = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleMouseUp = (e) => {
    if (clickedOutside && modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
    setClickedOutside(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
      <div ref={modalRef} className="relative flex flex-col max-w-full max-h-full">
        <div className="relative">
          <img 
            src={images[currentIndex]} 
            alt={`Full screen view ${currentIndex + 1}`} 
            className="max-w-full max-h-[calc(100vh-120px)] object-contain mx-auto"
          />
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 bg-white bg-opacity-50 hover:bg-opacity-100 rounded-full p-2 transition-colors duration-200"
          >
            <X size={24} />
          </button>
          {images.length > 1 && (
            <>
              <button 
                onClick={onPrev} 
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-100 rounded-full p-2 transition-colors duration-200"
              >
                <ChevronLeft size={24} />
              </button>
              <button 
                onClick={onNext} 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-100 rounded-full p-2 transition-colors duration-200"
              >
                <ChevronRight size={24} />
              </button>
            </>
          )}
        </div>
        <div className="h-24 bg-gradient-to-t from-black to-transparent">
          <div 
            ref={thumbnailsRef} 
            className="flex justify-start items-end h-full overflow-x-auto space-x-2 px-4"
          >
            {images.map((image, index) => (
              <img
                key={index}
                ref={index === currentIndex ? currentThumbnailRef : null}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className={`h-16 w-24 object-cover cursor-pointer transition-all duration-300 rounded-lg ${
                  index === currentIndex 
                    ? 'border-2 border-white scale-110 -translate-y-2' 
                    : 'opacity-70 hover:opacity-100 hover:-translate-y-1'
                }`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenImage;