import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import axios from 'axios';
import { X, Bed, Bath, Calendar, Home, ChevronLeft, ChevronRight, Clock, ChevronDown, ChevronUp, DollarSign, Building, Star, Share2, Trees, Banknote, Droplet, Mountain, Grid, Ruler, Car, Shield, Utensils, Thermometer, Wind, Users } from 'lucide-react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import FullScreenImage from './FullScreenImage';
import AgentContactBox from './AgentContactBox';
import { UserContext } from './UserContext';
import { shuffle } from 'lodash';
import { getNeighborhoodImage } from './neighborhoods';

const PropertyModal = ({ property, onClose, onOpenAuthModal, user, onSaveProperty, savedProperties, onReady }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAllPriceHistory, setShowAllPriceHistory] = useState(false);
  const [showAllTaxHistory, setShowAllTaxHistory] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const descriptionRef = useRef(null);
  const modalRef = useRef(null);
  const { updateUser } = useContext(UserContext);
  const [isSaved, setIsSaved] = useState(false);
  const [showAllFacts, setShowAllFacts] = useState(false);
  const randomPhotosRef = useRef(null);
  const [viewState, setViewState] = useState(null);
  const [neighborhoodInfo, setNeighborhoodInfo] = useState(null);
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  // Add this new constant for consistent heading styling
  const sectionHeadingClass = "text-2xl font-light mb-4 tracking-wider uppercase";

  // Move this function definition earlier
  const openFullScreenImage = useCallback((index) => {
    setFullScreenImageIndex(index);
    setIsFullScreen(true);
  }, []);

  useEffect(() => {
    const fetchPropertyData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/${property.zpid}`);
        console.log('Property details:', response.data);
        console.log('Neighborhood:', response.data.neighborhood);  // Add this line
        setDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching property details:', error);
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [property.zpid]);

  useEffect(() => {
    const checkDescriptionOverflow = () => {
      const element = descriptionRef.current;
      if (element) {
        const isOverflowing = element.scrollHeight > element.clientHeight;
        setShowFullDescription(!isOverflowing);
      }
    };

    checkDescriptionOverflow();
    window.addEventListener('resize', checkDescriptionOverflow);
    return () => window.removeEventListener('resize', checkDescriptionOverflow);
  }, [details?.description]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isFullScreen && modalRef.current && !modalRef.current.contains(event.target)) {
        if (!event.target.closest('.auth-modal')) {
          onClose();
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [onClose, isFullScreen]);

  useEffect(() => {
    if (user && user.savedPosts) {
      setIsSaved(user.savedPosts.includes(property.zpid.toString()));
    }
  }, [user, property.zpid]);

  useEffect(() => {
    if (details && details.latitude && details.longitude) {
      setViewState({
        latitude: details.latitude,
        longitude: details.longitude,
        zoom: 15
      });
    }
  }, [details]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const fetchNeighborhoodInfo = async () => {
      if (details && details.neighborhood) {
        try {
          console.log('Fetching neighborhood info for:', details.neighborhood);
          const encodedNeighborhood = encodeURIComponent(details.neighborhood);
          console.log('Encoded neighborhood:', encodedNeighborhood);
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/neighborhoods/${encodedNeighborhood}`);
          console.log('Neighborhood info response:', response.data);
          setNeighborhoodInfo(response.data);
        } catch (error) {
          console.error('Error fetching neighborhood info:', error.response ? error.response.data : error.message);
          console.error('Full error object:', error);
        }
      }
    };

    fetchNeighborhoodInfo();
  }, [details]);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Call onReady when the component is mounted and rendered
    onReady && onReady();
  }, [onReady]);

  const handleShare = () => {
    const formattedAddress = formatAddress(property.address);
    const encodedAddress = encodeURIComponent(`${formattedAddress.street}-${formattedAddress.cityStateZip}`.replace(/\s/g, '-').toLowerCase());
    const url = `${window.location.origin}/homes-for-sale/${encodedAddress}/${property.zpid}`;
    navigator.clipboard.writeText(url)
      .then(() => alert('Link copied to clipboard!'))
      .catch(err => console.error('Failed to copy: ', err));
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderFeatureTags = () => {
    const tags = [];
    if (details?.whatsSpecial) {
      tags.push(...details.whatsSpecial);
    }
    // Add any other tags you want to include
    return tags;
  };

  const handleSaveProperty = () => {
    if (!user) {
      onOpenAuthModal('signup');
      return;
    }
    onSaveProperty(property.zpid);
  };

  const formatAddress = (address) => {
    if (typeof address === 'string') return address;
    return {
      street: address.streetAddress,
      cityStateZip: `${address.city}, ${address.state} ${address.zipcode}`
    };
  };

  const renderFactsAndFeatures = () => {
    if (!details || !details.resoFacts) return null;

    const { resoFacts } = details;

    const sections = [
      {
        title: 'Interior Features',
        items: [
          { label: 'Bedrooms', value: details.bedrooms || 'N/A' },
          { label: 'Bathrooms', value: details.bathrooms || 'N/A' },
          { label: 'Full Bathrooms', value: resoFacts.bathroomsFull || 'N/A' },
          { label: 'Living Area', value: details.livingArea ? `${formatNumber(details.livingArea)} sqft` : 'N/A' },
          { label: 'Flooring', value: resoFacts.flooring ? resoFacts.flooring.join(', ') : 'N/A' },
          { label: 'Appliances', value: resoFacts.appliances ? resoFacts.appliances.join(', ') : 'N/A' },
          { label: 'Room Types', value: resoFacts.roomTypes ? resoFacts.roomTypes.join(', ') : 'N/A' },
          { label: 'Interior Features', value: resoFacts.interiorFeatures ? resoFacts.interiorFeatures.join(', ') : 'N/A' },
        ],
      },
      {
        title: 'Exterior Features',
        items: [
          { label: 'Lot Size', value: resoFacts.lotSize || 'N/A' },
          { label: 'Year Built', value: details.yearBuilt || 'N/A' },
          { label: 'Architectural Style', value: resoFacts.architecturalStyle || 'N/A' },
          { label: 'Exterior Features', value: resoFacts.exteriorFeatures ? resoFacts.exteriorFeatures.join(', ') : 'N/A' },
          { label: 'Pool', value: resoFacts.poolFeatures ? resoFacts.poolFeatures.join(', ') : 'No' },
          { label: 'Parking', value: resoFacts.parkingFeatures ? resoFacts.parkingFeatures.join(', ') : 'N/A' },
          { label: 'Lot Features', value: resoFacts.lotFeatures ? resoFacts.lotFeatures.join(', ') : 'N/A' },
        ],
      },
      {
        title: 'Systems and Utilities',
        items: [
          { label: 'Security', value: resoFacts.securityFeatures ? resoFacts.securityFeatures.join(', ') : 'N/A' },
          { label: 'Heating', value: resoFacts.heating || 'N/A' },
          { label: 'Cooling', value: resoFacts.cooling || 'N/A' },
          { label: 'Water', value: resoFacts.water || 'N/A' },
          { label: 'Sewer', value: resoFacts.sewer ? resoFacts.sewer.join(', ') : 'N/A' },
          { label: 'Utilities', value: resoFacts.utilities ? resoFacts.utilities.join(', ') : 'N/A' },
        ],
      },
      {
        title: 'Other Information',
        items: [
          { label: 'Zoning', value: resoFacts.zoning || 'N/A' },
          { label: 'Foundation', value: resoFacts.foundationDetails ? resoFacts.foundationDetails.join(', ') : 'N/A' },
          { label: 'Building Features', value: resoFacts.buildingFeatures ? resoFacts.buildingFeatures.join(', ') : 'N/A' },
          { label: 'Community Features', value: resoFacts.communityFeatures ? resoFacts.communityFeatures.join(', ') : 'N/A' },
          { label: 'View', value: resoFacts.view ? resoFacts.view.join(', ') : 'N/A' },
        ],
      },
    ];

    return (
      <div className="mb-6">
        <h3 className={sectionHeadingClass}>Facts & Features</h3>
        <div className={`relative ${!showAllFacts ? 'max-h-64 overflow-hidden' : ''}`}>
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-6">
              <h4 className="text-xl font-light mb-2 p-2 rounded bg-[#141414]">
                {section.title}
              </h4>
              <ul className="list-disc pl-5">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="mb-1">
                    <span className="text-gray-400">{item.label}:</span> {item.value}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {!showAllFacts && (
            <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-black to-transparent"></div>
          )}
        </div>
        <button 
          className="mt-2 text-white font-bold hover:text-blue-300 flex items-center"
          onClick={() => setShowAllFacts(!showAllFacts)}
        >
          {showAllFacts ? 'Show less' : 'Show more'}
          <ChevronDown className={`ml-1 transform ${showAllFacts ? 'rotate-180' : ''}`} size={16} />
        </button>
      </div>
    );
  };

  const renderThreePhotos = useMemo(() => {
    if (!property.images || property.images.length < 4) return null;

    if (!randomPhotosRef.current) {
      randomPhotosRef.current = shuffle(property.images).slice(0, 4);
    }

    const [mainPhoto, ...smallPhotos] = randomPhotosRef.current;

    return (
      <div className="mb-16">
        {/* Desktop view */}
        <div className="hidden md:flex h-[600px] gap-4">
          <div className="w-2/3">
            <img
              src={mainPhoto}
              alt="Main property photo"
              className="w-full h-full object-cover rounded-lg cursor-pointer"
              onClick={() => openFullScreenImage(property.images.indexOf(mainPhoto))}
            />
          </div>
          <div className="w-1/3 flex flex-col gap-4">
            {smallPhotos.slice(0, 2).map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Property photo ${index + 2}`}
                className="w-full h-1/2 object-cover rounded-lg cursor-pointer"
                onClick={() => openFullScreenImage(property.images.indexOf(photo))}
              />
            ))}
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden grid grid-cols-2 gap-2">
          {randomPhotosRef.current.map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`Property photo ${index + 1}`}
              className="w-full h-40 object-cover rounded-lg cursor-pointer"
              onClick={() => openFullScreenImage(property.images.indexOf(photo))}
            />
          ))}
        </div>
      </div>
    );
  }, [property.images, openFullScreenImage]);

  const renderMap = () => {
    if (!viewState) return null;

    return (
      <div className="mb-16">
        <div className="h-[400px] w-full">
          <Map
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            mapStyle="mapbox://styles/mapbox/dark-v10"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          >
            <NavigationControl position="top-right" />
            <Marker
              longitude={details.longitude}
              latitude={details.latitude}
              anchor="bottom"
            >
              <div 
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#dcb482',
                  borderRadius: '50%',
                  border: '2px solid white',
                }}
              />
            </Marker>
          </Map>
        </div>
      </div>
    );
  };

  const renderNeighborhoodSection = () => {
    if (!neighborhoodInfo || !details.neighborhood) return null;

    const neighborhoodImage = getNeighborhoodImage(details.neighborhood);

    return (
      <div className="mb-16">
        <h3 className={sectionHeadingClass}>About {details.neighborhood}</h3>
        <div className="bg-gradient-to-r from-[#201c1c] to-[#474141] rounded-lg overflow-hidden shadow-lg border border-gray-700">
          <div className="relative h-96">
            {neighborhoodImage && (
              <img
                src={neighborhoodImage}
                alt={details.neighborhood}
                className="w-full h-full object-cover"
              />
            )}
            <div className="absolute inset-0 bg-gradient-to-r from-black via-black to-transparent opacity-70 flex items-center">
              <div className="p-6 w-full md:w-3/5 h-full overflow-y-auto">
                <div className="max-h-full overflow-y-auto">
                  <p className="text-white text-lg">
                    {neighborhoodInfo.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!property) return null;

  const images = property.images || [];

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const formatNumber = (num) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '--';
  };

  const renderListingTags = () => {
    const tags = [];
    if (details?.listingSubType) {
      if (details.listingSubType.is_coming_Soon) tags.push('Coming Soon');
      if (details.listingSubType.is_new_Home) tags.push('New Home');
      if (details.listingSubType.is_pending) tags.push('Pending');
      if (details.listingSubType.is_for_Auction) tags.push('For Auction');
      if (details.listingSubType.is_foreclosure) tags.push('Foreclosure');
      if (details.listingSubType.is_bank_Owned) tags.push('Bank Owned');
      if (details.listingSubType.is_open_House) tags.push('Open House');
    }
    return tags;
  };

  const handleBackgroundImageClick = (event) => {
    // Check if it's a mobile device (you can adjust the width as needed)
    if (window.innerWidth < 1148) {
      openFullScreenImage(0);
    }
  };

  if (loading || !details) {
    return <div>Loading...</div>;
  }

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="loader" style={{
          border: '4px solid #f3f3f3',
          borderTop: '4px solid #3498db',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          animation: 'spin 1s linear infinite',
        }}></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[60] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
      <div ref={modalRef} className="bg-black rounded-lg w-11/12 max-w-7xl my-4 text-gray-300 overflow-y-auto max-h-[calc(100vh-2rem)]" onClick={(e) => e.stopPropagation()}>
        <div className="relative h-[80vh] p-6">
          {/* Background image for both mobile and desktop */}
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${images[0]})` }}
            onClick={handleBackgroundImageClick}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-90 pointer-events-none"></div>
          </div>

          {/* Desktop view - additional images */}
          <div className="relative z-10 hidden md:flex h-full gap-4">
            <div className="w-2/3 h-full">
              <img 
                src={images[0]} 
                alt="Main property image"
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => openFullScreenImage(0)}
              />
            </div>
            <div className="w-1/3 h-full flex flex-col gap-4">
              {images.slice(1, 3).map((image, index) => (
                <div key={index} className="h-[calc(50%-0.5rem)] relative">
                  <img
                    src={image}
                    alt={`Property image ${index + 2}`}
                    className="w-full h-full object-cover rounded-lg cursor-pointer"
                    onClick={() => openFullScreenImage(index + 1)}
                  />
                </div>
              ))}
            </div>
            <button
              className="absolute bottom-4 right-4 bg-white text-black px-4 py-2 rounded-lg font-semibold hover:bg-opacity-90 transition-colors duration-200"
              onClick={() => openFullScreenImage(0)}
            >
              See all photos
            </button>
          </div>

          {/* Mobile view - overlay data */}
          <div className="md:hidden absolute bottom-4 left-5 right-5 z-20 flex justify-between items-end">
            <div className="flex space-x-4 text-white">
              <div className="text-center">
                <p className="text-2xl font-light">{details?.bedrooms || 'N/A'}</p>
                <p className="text-xs font-light">beds</p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-light">{details?.bathrooms || 'N/A'}</p>
                <p className="text-xs font-light">baths</p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-light">{details?.livingArea ? formatNumber(details.livingArea) : 'N/A'}</p>
                <p className="text-xs font-light">sqft</p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-light">
                  {details?.resoFacts?.lotSize 
                    ? details.resoFacts.lotSize.toLowerCase().includes('acres')
                      ? formatNumber(parseFloat(details.resoFacts.lotSize.match(/^[\d.]+/)?.[0] || '0')) || 'N/A'
                      : 'N/A'
                    : 'N/A'}
                </p>
                <p className="text-xs font-light">acres</p>
              </div>
            </div>
            <button 
              className="bg-white bg-opacity-30 text-white px-3 py-2 rounded-lg text-sm font-semibold"
              onClick={() => openFullScreenImage(0)}
            >
              See all
            </button>
          </div>

          {/* Buttons for save, share, and close */}
          <div className="absolute top-8 right-8 flex space-x-2 z-20">
            <button 
              className="bg-white bg-opacity-50 hover:bg-opacity-100 rounded-full p-2 transition-colors duration-200"
              onClick={handleSaveProperty}
            >
              <Star size={24} fill={savedProperties.includes(property.zpid.toString()) ? "currentColor" : "none"} color="#4A4A4A" />
            </button>
            <button 
              onClick={handleShare}
              className="bg-white bg-opacity-50 hover:bg-opacity-100 rounded-full p-2 transition-colors duration-200"
            >
              <Share2 size={24} color="#4A4A4A" />
            </button>
            <button 
              onClick={onClose} 
              className="bg-white bg-opacity-50 hover:bg-opacity-100 rounded-full p-2 transition-colors duration-200"
            >
              <X size={24} color="#4A4A4A" />
            </button>
          </div>
        </div>

        <div className="p-6 flex flex-col md:flex-row">
          <div className="flex-grow pr-0 md:pr-6">
            <div className="flex flex-col md:flex-row justify-between items-start mb-6">
              <div>
                <h2 className="text-3xl font-light mb-2 text-white">{formatPrice(details?.price || property.price)}</h2>
                <p className="text-xl font-light text-white">
                  {typeof property.address === 'string' 
                    ? property.address 
                    : `${property.address.streetAddress || ''}, ${property.address.city || ''}, ${property.address.state || ''} ${property.address.zipcode || ''}`
                  }
                </p>
              </div>
              {/* Desktop view data */}
              <div className="hidden md:flex flex-wrap justify-between w-full md:w-auto md:space-x-6 text-center mt-4 md:mt-0">
                <div className="md:w-auto mb-4 md:mb-0">
                  <p className="text-3xl font-light text-white">{details?.bedrooms || 'N/A'}</p>
                  <p className="text-sm font-light text-gray-400">beds</p>
                </div>
                <div className="md:w-auto mb-4 md:mb-0">
                  <p className="text-3xl font-light text-white">{details?.bathrooms || 'N/A'}</p>
                  <p className="text-sm font-light text-gray-400">baths</p>
                </div>
                <div className="md:w-auto mb-4 md:mb-0">
                  <p className="text-3xl font-light text-white">{details?.livingArea ? formatNumber(details.livingArea) : 'N/A'}</p>
                  <p className="text-sm font-light text-gray-400">sqft</p>
                </div>
                <div className="md:w-auto">
                  <p className="text-3xl font-light text-white">
                    {details?.resoFacts?.lotSize 
                      ? details.resoFacts.lotSize.toLowerCase().includes('acres')
                        ? formatNumber(parseFloat(details.resoFacts.lotSize.match(/^[\d.]+/)?.[0] || '0')) || 'N/A'
                        : 'N/A'
                      : 'N/A'}
                  </p>
                  <p className="text-sm font-light text-gray-400">acres</p>
                </div>
              </div>
            </div>

            {/* Agent and Architecture info for mobile */}
            <div className="hidden mb-6">
  <div className="flex flex-wrap justify-between">
    {details?.listed_by && (
      <div className="w-full mb-4">
        <AgentContactBox
          agent={details.listed_by}
          propertyStyle={details?.resoFacts?.architecturalStyle}
          mobileImageClass="h-56 md:h-32"
        />
      </div>
    )}
  </div>
</div>

            <div className="relative mb-6 rounded-lg overflow-hidden">
              <div 
                className="absolute inset-0 bg-cover bg-center" 
                style={{
                  backgroundImage: `url(${images[3]})`,
                }}
              ></div>
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="relative z-10 p-4 md:p-8">
                {/* Mobile view */}
                <div className="md:hidden">
                  <div className="grid grid-cols-2 gap-3 mb-4">
                    <div className="bg-black bg-opacity-70 p-3 rounded-lg flex items-center space-x-2">
                      <div className="text-white"><Calendar size={16} /></div>
                      <div>
                        <p className="text-xs font-light text-white">Built in</p>
                        <p className="text-sm font-light text-white">{details?.yearBuilt || 'N/A'}</p>
                      </div>
                    </div>
                    {details?.resoFacts?.architecturalStyle && (
                      <div className="bg-black bg-opacity-70 p-3 rounded-lg flex items-center space-x-2">
                        <div className="text-white"><Building size={16} /></div>
                        <div>
                          <p className="text-xs font-light text-white">Style</p>
                          <p className="text-sm font-light text-white truncate">{details.resoFacts.architecturalStyle}</p>
                        </div>
                      </div>
                    )}
                    <div className="bg-black bg-opacity-70 p-3 rounded-lg flex items-center space-x-2">
                      <div className="text-white"><Banknote size={16} /></div>
                      <div>
                        <p className="text-xs font-light text-white">Price per sqft</p>
                        <p className="text-sm font-light text-white">
                          {details?.price && details?.livingArea
                            ? `$${(details.price / details.livingArea).toLocaleString(undefined, {maximumFractionDigits: 0})}`
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Feature Tags */}
                  <div className="flex flex-wrap gap-2">
                    {renderFeatureTags().map((tag, index) => (
                      <span 
                        key={index} 
                        className="bg-black bg-opacity-70 text-white px-2 py-1 rounded-full text-[10px] font-light"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Desktop view (unchanged) */}
                <div className="hidden md:block">
                  <div className="grid grid-cols-3 gap-6 mb-8">
                    <div className="bg-black bg-opacity-70 p-6 rounded-lg flex items-center space-x-4">
                      <div className="text-white"><Calendar size={24} /></div>
                      <div>
                        <p className="text-base font-light text-white">Built in</p>
                        <p className="text-lg font-light text-white">{details?.yearBuilt || 'N/A'}</p>
                      </div>
                    </div>
                    {details?.resoFacts?.architecturalStyle && (
                      <div className="bg-black bg-opacity-70 p-6 rounded-lg flex items-center space-x-4">
                        <div className="text-white"><Building size={24} /></div>
                        <div>
                          <p className="text-base font-light text-white">Style</p>
                          <p className="text-lg font-light text-white">{details.resoFacts.architecturalStyle}</p>
                        </div>
                      </div>
                    )}
                    <div className="bg-black bg-opacity-70 p-6 rounded-lg flex items-center space-x-4">
                      <div className="text-white"><Banknote size={24} /></div>
                      <div>
                        <p className="text-base font-light text-white">Price per sqft</p>
                        <p className="text-lg font-light text-white">
                          {details?.price && details?.livingArea
                            ? `$${(details.price / details.livingArea).toLocaleString(undefined, {maximumFractionDigits: 0})}`
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Feature Tags */}
                  <div className="flex flex-wrap gap-3">
                    {renderFeatureTags().map((tag, index) => (
                      <span 
                        key={index} 
                        className="bg-black bg-opacity-70 text-white px-4 py-2 rounded-full text-base font-light"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {details?.description && (
              <div className="mb-6">
                <h3 className={sectionHeadingClass}>About this home</h3>
                <div
                  ref={descriptionRef}
                  className={`relative ${
                    showFullDescription ? '' : 'h-24 overflow-hidden'
                  }`}
                >
                  <p>
                    {details.description.split('. ').map((sentence, index) => (
                      <React.Fragment key={index}>
                        {sentence.trim()}.{' '}
                        {(index + 1) % 3 === 0 && (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </p>
                  {!showFullDescription && (
                    <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-black to-transparent"></div>
                  )}
                </div>
                <button
                  className="mt-2 flex items-center text-white font-bold hover:text-blue-300"
                  onClick={toggleDescription}
                >
                  {showFullDescription ? 'Show less' : 'Show more'}
                  {showFullDescription ? (
                    <ChevronUp className="ml-1" size={16} />
                  ) : (
                    <ChevronDown className="ml-1" size={16} />
                  )}
                </button>
              </div>
            )}

            {/* Add separator and apply to all following sections */}
            <div className="border-t border-gray-700 my-6"></div>

            {details?.datePosted && (
              <div className="mb-6">
                <p className="flex items-center">
                  <Calendar className="mr-2" size={20} />
                  <span>Listed on {formatDate(details.datePosted)}</span>
                </p>
              </div>
            )}
            {renderListingTags().length > 0 && (
              <div className="mb-6">
                <h3 className={sectionHeadingClass}>Listing Details</h3>
                <div className="flex flex-wrap gap-2">
                  {renderListingTags().map((tag, index) => (
                    <span key={index} className="bg-gray-800 text-gray-300 px-2 py-1 rounded-full text-sm font-light">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}

            <div className="border-t border-gray-700 my-6"></div>

            {renderFactsAndFeatures()}

            <div className="border-t border-gray-700 my-6"></div>

            {renderThreePhotos}

            {renderMap()}

            <div className="border-t border-gray-700 my-6"></div>

            {renderNeighborhoodSection()}

            {details?.priceHistory && details.priceHistory.length > 0 && (
              <div className="mb-6">
                <h3 className={sectionHeadingClass}>Price history</h3>
                <div className="w-full overflow-x-auto">
                  <table className="w-full">
                    <thead className="hidden md:table-header-group">
                      <tr className="border-b border-gray-700">
                        <th className="w-32 py-2 font-light text-left">Date</th>
                        <th className="py-2 font-light text-center">Event</th>
                        <th className="w-40 py-2 font-light text-right">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(showAllPriceHistory ? details.priceHistory : details.priceHistory.slice(0, 3)).map((item, index) => (
                        <tr key={index} className="md:border-b md:border-gray-700">
                          <td className="md:w-32 md:py-4 block md:table-cell mb-1 md:mb-0">
                            <span className="md:hidden font-light text-gray-400">Date: </span>
                            {formatDate(item.date)}
                          </td>
                          <td className="md:py-4 md:text-center block md:table-cell mb-1 md:mb-0">
                            <span className="md:hidden font-light text-gray-400">Event: </span>
                            {item.event}
                          </td>
                          <td className="md:w-40 md:py-4 md:text-right block md:table-cell mb-4 md:mb-0">
                            <span className="md:hidden font-light text-gray-400 inline-block mr-2">Price: </span>
                            <span className="inline-block md:block">
                              {item.price === null ? 'N/A' : formatPrice(item.price)}
                              {item.priceChangeRate !== null && item.price !== null && (
                                <span className={`ml-2 ${item.priceChangeRate > 0 ? 'text-green-400' : 'text-red-400'}`}>
                                  {item.priceChangeRate > 0 ? '+' : ''}
                                  <span className="md:hidden">
                                    {Math.floor(item.priceChangeRate * 100)}%
                                  </span>
                                  <span className="hidden md:inline">
                                    {(item.priceChangeRate * 100).toFixed(1)}%
                                  </span>
                                </span>
                              )}
                            </span>
                            <div className="text-sm text-gray-400 hidden md:block">
                              {item.pricePerSquareFoot !== null && `$${formatNumber(item.pricePerSquareFoot)}/sqft`}
                            </div>
                          </td>
                          {/* Add a thin grey line after each entry on mobile */}
                          <td className="md:hidden block border-b border-gray-700 my-2"></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {details.priceHistory.length > 3 && (
                  <button 
                    className="mt-2 flex items-center text-white font-bold hover:text-blue-300"
                    onClick={() => setShowAllPriceHistory(!showAllPriceHistory)}
                  >
                    {showAllPriceHistory ? 'Show less' : 'Show more'}
                    <ChevronDown className={`ml-1 transform ${showAllPriceHistory ? 'rotate-180' : ''}`} size={16} />
                  </button>
                )}
              </div>
            )}

            {details?.taxHistory && details.taxHistory.length > 0 && (
              <div className="mb-6">
                <h3 className={sectionHeadingClass}>Public tax history</h3>
                <div className="w-full">
                  <div className="hidden md:flex border-b border-gray-700">
                    <div className="w-32 py-2 font-light">Year</div>
                    <div className="flex-grow text-center py-2 font-light">Property taxes</div>
                    <div className="w-40 text-right py-2 font-light">Tax assessment</div>
                  </div>
                  {(showAllTaxHistory ? details.taxHistory : details.taxHistory.slice(0, 3)).map((item, index) => (
                    <div key={index} className="md:flex md:border-b md:border-gray-700">
                      <div className="md:w-32 md:py-2 mb-1 md:mb-0">
                        <span className="md:hidden font-light text-gray-400">Year: </span>
                        {new Date(item.time).getFullYear()}
                      </div>
                      <div className="md:flex-grow md:text-center md:py-2 mb-1 md:mb-0">
                        <span className="md:hidden font-light text-gray-400 inline-block mr-2">Property taxes: </span>
                        <span className="inline-block md:block">
                          {item.taxPaid === null ? 'N/A' : formatPrice(item.taxPaid)}
                          {item.taxIncreaseRate !== null && item.taxPaid !== null && (
                            <span className={`ml-2 ${item.taxIncreaseRate > 0 ? 'text-green-400' : 'text-red-400'}`}>
                              {item.taxIncreaseRate > 0 ? '+' : ''}
                              <span className="md:hidden">
                                {Math.floor(item.taxIncreaseRate * 100)}%
                              </span>
                              <span className="hidden md:inline">
                                {(item.taxIncreaseRate * 100).toFixed(1)}%
                              </span>
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="md:w-40 md:text-right md:py-2 mb-4 md:mb-0">
                        <span className="md:hidden font-light text-gray-400 inline-block mr-2">Tax assessment: </span>
                        <span className="inline-block md:block">
                          {item.value === null ? 'N/A' : formatPrice(item.value)}
                          {item.valueIncreaseRate !== null && item.value !== null && (
                            <span className={`ml-2 ${item.valueIncreaseRate > 0 ? 'text-green-600' : 'text-red-600'}`}>
                              {item.valueIncreaseRate > 0 ? '+' : ''}
                              <span className="md:hidden">
                                {Math.floor(item.valueIncreaseRate * 100)}%
                              </span>
                              <span className="hidden md:inline">
                                {(item.valueIncreaseRate * 100).toFixed(1)}%
                              </span>
                            </span>
                          )}
                        </span>
                      </div>
                      {/* Add a thin grey line after each entry on mobile */}
                      <div className="md:hidden block border-b border-gray-700 my-2"></div>
                    </div>
                  ))}
                </div>
                {details.taxHistory.length > 3 && (
                  <button 
                    className="mt-2 flex items-center text-white font-bold hover:text-blue-300"
                    onClick={() => setShowAllTaxHistory(!showAllTaxHistory)}
                  >
                    {showAllTaxHistory ? 'Show less' : 'Show more'}
                    <ChevronDown className={`ml-1 transform ${showAllTaxHistory ? 'rotate-180' : ''}`} size={16} />
                  </button>
                )}
              </div>
            )}

            {details?.schools && details.schools.length > 0 && (
              <div className="mb-6">
                <h3 className={sectionHeadingClass}>Nearby schools</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {details.schools.map((school, index) => {
                    const ratingColor = school.rating 
                      ? `hsl(${Math.min(school.rating * 12, 120)}, 70%, 50%)`
                      : '#6b7280';

                    return (
                      <div key={index} className="bg-gradient-to-r from-[#201c1c] to-[#474141] rounded-lg p-4 shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-[1.02]">
                        <div className="flex items-center space-x-3">
                          <div 
                            className="w-12 h-12 md:w-12 md:h-12 rounded-full flex-shrink-0 flex items-center justify-center text-lg font-bold text-white shadow-inner relative"
                            style={{ 
                              background: `conic-gradient(${ratingColor} ${school.rating * 10}%, transparent ${school.rating * 10}% 100%)`,
                            }}
                          >
                            <div className="w-10 h-10 md:w-10 md:h-10 rounded-full bg-black absolute inset-0 m-auto flex items-center justify-center">
                              {school.rating ? `${school.rating}` : 'N/A'}
                            </div>
                          </div>
                          <div className="flex-grow min-w-0">
                            <h4 className="text-base font-semibold text-white mb-1 truncate">{school.name}</h4>
                            <p className="text-xs text-gray-400 truncate">{school.level} • {school.type}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {/* Desktop AgentContactBox */}
          <div className="hidden md:block w-64 flex-shrink-0 bg-black mt-6 md:mt-0">
            {details?.listed_by ? (
              <AgentContactBox
                agent={details.listed_by}
                propertyStyle={details?.resoFacts?.architecturalStyle}
              />
            ) : (
              <div className="p-4 rounded-lg">
                <p>Agent information not available</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile AgentContactBox */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 z-50">
        {details?.listed_by && (
          <AgentContactBox
            agent={details.listed_by}
            propertyStyle={details?.resoFacts?.architecturalStyle}
            isMobile={true}
          />
        )}
      </div>

      {isFullScreen && images.length > 0 && (
        <FullScreenImage
          images={property.images}
          onClose={toggleFullScreen}
          initialIndex={fullScreenImageIndex}
        />
      )}
    </div>
  );
};

const spinKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.innerHTML = spinKeyframes;
  document.head.appendChild(style);
}

export default PropertyModal;